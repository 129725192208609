import axios from "axios"
import { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BACKEND_URL } from "../utils/constants"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../localStoragesItemsName"
import { selectorAuthState, setAuthStatus } from "../store/authSlice"

interface iPropsAuthWrapper{
  children: ReactNode
}

const AuthWrapper=({children}:iPropsAuthWrapper)=>{
  const dispatch = useDispatch()

  const currentAuthState = useSelector(selectorAuthState)
  useEffect(()=>{
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)
    if(refreshToken){
      axios.post(`${BACKEND_URL}/login/refresh/`, {refresh: localStorage.getItem(REFRESH_TOKEN)}).then(result=>{
        localStorage.setItem(ACCESS_TOKEN, result.data.accessToken)
        dispatch(setAuthStatus(true))
      }).catch(error=>{
        dispatch(setAuthStatus(false))
      })
    }else{
      dispatch(setAuthStatus(false)) 
    }
  },[dispatch])

  return(
  <>
    {currentAuthState!==undefined&&children}
  </>
  )
}
export default AuthWrapper
import { MouseEventHandler, ReactNode, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectVisibleModalWindow, setVisible } from "../store/modalSlice"

interface iPropsModalWindow{
  children: ReactNode
}

const ModalWindow=({children}:iPropsModalWindow)=>{
  const visible = useSelector(selectVisibleModalWindow)
  const dispatch = useDispatch()

  const escapePress= useCallback((event: KeyboardEvent)=>{
    if(event.key === 'Escape'){
      dispatch(setVisible(false))
    }
  }, [dispatch])

  useEffect(()=>{
    window.document.addEventListener('keyup', escapePress)
    return ()=>{
      window.document.removeEventListener('keyup', escapePress)
    }
  },[escapePress])

  const clickParentContainer:MouseEventHandler=()=>{
    dispatch(setVisible(false))
  }

  const clickChildContainer:MouseEventHandler=(event)=>{
    event.stopPropagation()
  }

  return(
    <>
    {visible&&
    <div className='fixed top-0 overflow-auto w-screen h-screen bg-transparent flex items-center justify-center backdrop-blur-md' onClick={clickParentContainer}>
      <div className='fixed top-0 bg-white min-h-[30%] min-w-[50%] max-w-[80%] shadow-2xl shadow-[#f0cae3] rounded-3xl overflow-hidden' onClick={clickChildContainer}>
        {children}
      </div>
    </div>
    }</>
    
  )
}

export default ModalWindow
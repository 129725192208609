export enum environmentTypes{
  production = 'production',
  develop = 'develop'
}

export type enableLanguages = 'en' | 'ru' | 'ua' | 'de' | 'fr'

export enum userControlStatus{
  active = 'Active',
  banned = 'Banned',
  expired = 'Expired',
  online = 'Online'
}

export interface iRowDataUserControl{
  numberRow?: number
  id?: number,
  email?: string,
  expire_date?: string,
  cert_name?: string,
  is_promocode?: boolean,
  lang?: string,
  online?: boolean,
  order_date?: string,
  plan?: number,
  price?: number,
  promocode?: string | null,
  sniffer:{
    city: string,
    country: string,
    date: string,
    ip: string,
    org: string
  }[],
  status?: userControlStatus
}
import { useState } from 'react'
import { languageName } from '../utils/constants'
import axiosInstance from '../utils/generally'
import dataLocation from '../location.json'
import { jsObjectToFormData } from '../utils/common'

interface iValidationInputs{
  [key: string]:{
    validationStatus: boolean,
    error?: string
  }
}

interface iDataInputs{
  ssh_host: string,
  ssh_user: string,
  ssh_password: string,
  location_name: string
}

const initialStateDataInputs:iDataInputs={
  ssh_host: '',
  ssh_user: '',
  ssh_password: '',
  location_name: ''
}

interface iLocation{
  location: string,
  name: string
}

const Location=()=>{
  const [dataInputs, setDataInputs] = useState(initialStateDataInputs) 
  const [validationFields, setValidationFields] = useState({ssh_host:{validationStatus: false}, 
                                                            ssh_user:{validationStatus: false},
                                                            ssh_password:{validationStatus: false},
                                                            location_name:{validationStatus: false} } as iValidationInputs)
  const [promptLocation, setPromptLocation]:[iLocation[], Function] = useState([])

  const submitForm: React.FormEventHandler=(event)=>{
    event.preventDefault()
    axiosInstance.post('/create_node', jsObjectToFormData(dataInputs))
    alert('You request to add new location has been accepted')
  }

  const validateFieldByName=(name:string, value:string)=>{
    switch(name){
      case 'ssh_host':{
        if(!value){
          setValidationFields({...validationFields, [name]:{
            validationStatus: false
          }})
          break 
        }
        if(!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)){
          setValidationFields({...validationFields, [name]:{
            validationStatus:false,
            error: 'data in ip-address field not match ip'
          }
        })
        }else{
          setValidationFields({...validationFields, [name]:{
            validationStatus: true
          }})
        }
        break
      }
      case 'location_name':{
        if(!value){
          setValidationFields({...validationFields, [name]:{
            validationStatus: false
          }})
          break 
        }

        // eslint-disable-next-line
        const re = new RegExp('[a-z]{2,3}[\-]{1}[a-z]{1,}')
        if(!re.test(value)){
          setValidationFields({...validationFields, [name]:{
            validationStatus:false,
            error: `the value doens't fit regex`
          }
        })
        }else{
          setValidationFields({...validationFields, [name]:{
            validationStatus: true
          }})
        }
        break
      }

      default:{
        if(!value){
          setValidationFields({...validationFields, [name]:{
            validationStatus: false
          }})
          break
        }
        
        setValidationFields({...validationFields, [name]:{
          validationStatus: true
        }})
      }

    }
  }

  const getGenerallyValidationStatus=():boolean=>{
    let resultValidation = true
    for(let index in validationFields){
      resultValidation = resultValidation&&validationFields[index].validationStatus
    }

    return resultValidation
  }

  const findSuggestionByLocationName=(valueInput:string):void=>{
    const tempArrayOfPrompts: iLocation[] = []
    dataLocation.locations.filter(element=>element.location.includes(valueInput.toLowerCase())).forEach(element=>{
      tempArrayOfPrompts.push({location: element.location, name: element[languageName] as string})
    })
    setPromptLocation(tempArrayOfPrompts)
  }

  const changeInputs: React.ChangeEventHandler=(event)=>{
    const target = event.target as HTMLInputElement

    validateFieldByName(target.name, target.value)
    if(target.name === 'location_name'){
      findSuggestionByLocationName(target.value)
    }
    setDataInputs({...dataInputs, [target.name]: target.value})
  }

  const selectPropmt=(location_name:string)=>{
    setDataInputs({...dataInputs, location_name})
    setPromptLocation([])
  }

  return(
    <div className='w-[70%] flex flex-col justify-center items-center px-[20px] py-[20px] mt-[30px] shadow-2xl shadow-[#f0cae3] rounded-3xl'>
      <h1 className='inline text-header-background-color'>Add new location</h1>
      <form className='flex flex-col justify-center items-center gap-y-[15px] py-[25px] min-w-[50%]' onSubmit={submitForm}>
        <div className='w-[100%] flex flex-col justify-center items-center'>
          <input  className={`w-[100%] shadow-md rounded-md border-2 ${validationFields.ssh_host && !validationFields.ssh_host.validationStatus&&'bg-rose-100'}`} 
                  type='text' 
                  name='ssh_host' 
                  id='ipAdress' 
                  placeholder='IP address' 
                  value={dataInputs.ssh_host} 
                  onChange={changeInputs} 
          />
          {validationFields.ip && !validationFields.ip.validationStatus&&<h5 className='text-sm text-rose-600 m-0 p-0'>{validationFields.ip.error}</h5>}  
        </div>

        <div className='w-[100%] flex flex-col justify-center items-center'>
          <input  className={`w-[100%] shadow-md rounded-md border-2 ${validationFields.ssh_user && !validationFields.ssh_user.validationStatus&&'bg-rose-100'}`} 
                  type='text' 
                  name='ssh_user' 
                  id='sshLogin' 
                  placeholder='SSH login' 
                  value={dataInputs.ssh_user} 
                  onChange={changeInputs} 
            />
        </div>

        <div className='w-[100%] flex flex-col justify-center items-center'>
          <input  className={`w-[100%] shadow-md rounded-md border-2 ${validationFields.ssh_password && !validationFields.ssh_password.validationStatus&&'bg-rose-100'}`} 
                  type='password' 
                  name='ssh_password' 
                  id='sshPassword' 
                  placeholder='SSH password' 
                  value={dataInputs.ssh_password} 
                  onChange={changeInputs} 
          />
        </div>

        <div className='w-[100%] flexflex-col items-stretch'>
          <input  className={`w-[100%] shadow-md rounded-md border-2 ${validationFields.location_name && !validationFields.location_name.validationStatus&&'bg-rose-100'}`} 
                  type='text' 
                  name='location_name' 
                  id='name' 
                  placeholder='Name location' 
                  value={dataInputs.location_name} 
                  onChange={changeInputs} 
          />
          {promptLocation.length>0&&promptLocation.length<5&&<ul className='relative bg-white border-2 shadow-md flex flex-col items-stretch gap-[10px]'>
            {promptLocation.map(element=>
            <li onClick={()=>{selectPropmt(element.location)}} className='hover:bg-header-background-color/75 cursor-pointer hover:text-white'>
              {element.location+' ('+element.name+')'}
            </li>
            )}</ul>}
        </div>
        {/* <select  className='min-w-full shadow-md rounded-md border-2' name='location' id='name' value={dataInputs.location} onChange={changeInputs} >
          {dataLocation.locations.map((element, index)=><option value={element.location} key={index}>{element[languageName]}</option>)}
        </select> */}
        <input  disabled={!getGenerallyValidationStatus()} 
                className={`rounded-[15px] px-[15px] py-2 mt-[30px] border-2 max-w-xs ${getGenerallyValidationStatus()?'bg-btn-secondary-color hover:bg-btn-secondary-color/75 hover:cursor-pointer':'bg-gray-300'}`} 
                type={'submit'} 
                value='Add location' 
          />
        
      </form>
    </div>
  ) 
}

export default Location
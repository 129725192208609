import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userControlStatus } from '../utils/interfaces'
import { RootState } from './store'

export interface iFilterState {
  [userControlStatus.active]: boolean,
  [userControlStatus.banned]: boolean,
  [userControlStatus.expired]: boolean,
  [userControlStatus.online]: boolean,
  plansOnly: Number,
  usedThePromocode: boolean,
  orderDateStart: string | null,
  orderDateTill: string | null,
  free: boolean
}

const initialState: iFilterState = {
  [userControlStatus.active]: false,
  [userControlStatus.banned]: false,
  [userControlStatus.expired]: false,
  [userControlStatus.online]: false,
  plansOnly: 0,
  usedThePromocode: false,
  orderDateStart: null,
  orderDateTill: null,
  free: false
}


export const controlUserFilterSlice = createSlice({
  name: 'controlUserFilter',
  initialState,
  reducers: {
    setFilter:(state, action:PayloadAction<iFilterState>)=>{
      Object.assign(state, action.payload)
    }
  },
})

// Action creators are generated for each case reducer function
export const { setFilter } = controlUserFilterSlice.actions

export const selectFilter=(state: RootState)=>state.controlUserFilter

export default controlUserFilterSlice.reducer
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import Switch from './Switch'
import { userControlStatus } from '../utils/interfaces'
import { DatePicker } from 'antd'
import type { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { selectFilter, setFilter } from '../store/userControlFilterSlice'
import dayjs from 'dayjs'
import axiosInstance from '../utils/generally'
import { setAuthStatus } from '../store/authSlice'
import { isAxiosError } from 'axios'

const { RangePicker } = DatePicker

interface iPropsUserControlFilter{
  changeVisible: Function
}


interface iFilterData{
  [key: string]: {
    active: boolean
    payload?:{
      [key: string]: any
    }[]
  }
}

interface iTariffs{
  id:number,
  term: number
}

const initialFilterState: iFilterData={
  [userControlStatus.active]:{
    active: false
  },
  [userControlStatus.banned]:{
    active: false
  },
  [userControlStatus.expired]:{
    active: false
  },
  [userControlStatus.online]:{
    active: false
  },
  'PlansOnly':{
    active: false,
    payload:[{value: 0}]
  },
  'UsedThePromocode':{
    active: false
  },
  'OrderDate':{
    active: false
  },
  'Free':{
    active: false
  },
}

const UserControlFilter=({changeVisible}:iPropsUserControlFilter)=>{
  const [filtersData, setFilterData] = useState(initialFilterState)
  const [tariffs, setTarrifs]:[iTariffs[], Function] = useState([])

  const dispatch = useDispatch()
  const filterState = useSelector(selectFilter)

  const escapePress= useCallback((event: KeyboardEvent)=>{
    if(event.key === 'Escape'){
      changeVisible(false) 
    }
  }, [changeVisible])

  useEffect(()=>{
    window.document.addEventListener('keyup', escapePress)
    return ()=>{
      window.document.removeEventListener('keyup', escapePress)
    }
  },[escapePress])

  useEffect(()=>{
    setFilterData({
      [userControlStatus.active]:{
        active: filterState.Active
      },
      [userControlStatus.banned]:{
        active: filterState.Banned
      },
      [userControlStatus.expired]:{
        active: filterState.Expired
      },
      [userControlStatus.online]:{
        active: filterState.Online
      },
      'PlansOnly':{
        active: filterState.plansOnly!==0?true:false,
        payload:[{value: filterState.plansOnly}]
      },
      'UsedThePromocode':{
        active: filterState.usedThePromocode
      },
      'OrderDate':{
        active: filterState.orderDateStart||filterState.orderDateTill?true:false,
        payload:[{'from': filterState.orderDateStart}, {'to': filterState.orderDateTill}]
      },
      'Free':{
        active: filterState.free
      }
    })
  },[filterState])

  useEffect(()=>{
    axiosInstance.get('/terms_list').then(result=>{
      if(result.data.tariffs){
        setTarrifs(result.data.tariffs)
      }
    }).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
        }
      }
    })
  },[dispatch])

  const outsideWrapperChangeFilterActivity=(name:string, value:Boolean)=>{
    const changeFilterActivity=()=>{
      if(name==='PlansOnly'){
        setFilterData({...filtersData, [name]:{active: !value, payload:[{value:0}]}})
      }else{
        setFilterData({...filtersData, [name]:{active: !value}})
      }
    }

    return changeFilterActivity
  }

  const clickCloseFilterPanel: MouseEventHandler=()=>{
    changeVisible(false)
  }

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setFilterData({...filtersData, 'OrderDate':{active: true, payload:[{'from': dates[0]?.toISOString()}, {'to': dates[1]?.toISOString()}]}})
    }else{
      setFilterData({...filtersData, 'OrderDate':{active: false}})
    }
  }

  const clickApply:MouseEventHandler=(event)=>{

    //pass filter data to redux slice and change visible 
    dispatch(setFilter({
      [userControlStatus.active]: filtersData[userControlStatus.active].active,
      [userControlStatus.banned]: filtersData[userControlStatus.banned].active,
      [userControlStatus.expired]: filtersData[userControlStatus.expired].active,
      [userControlStatus.online]: filtersData[userControlStatus.online].active,
      'plansOnly': filtersData['PlansOnly'].payload?Number(filtersData['PlansOnly'].payload[0]['value']):0,
      'usedThePromocode': filtersData['UsedThePromocode'].active,
      'orderDateStart': filtersData['OrderDate'].payload?filtersData['OrderDate'].payload[0]['from']:null,
      'orderDateTill': filtersData['OrderDate'].payload?filtersData['OrderDate'].payload[1]['to']:null,
      'free': filtersData['Free'].active
      
    }))
    changeVisible(false)
  }

  const changeTerm:ChangeEventHandler=(event)=>{
    const target = event.target as HTMLInputElement
      setFilterData({...filtersData, 'PlansOnly':{active: Number(target.value)!==0, payload:[{value: target.value}]}})
  }
  return (
    <div className='fixed bottom-0 left-0 h-[85%] border-white bg-white shadow-lg' >
      <button className='absolute top-1 right-1 text-sm text-white border-2 border-red px-[5px] py-[2px] transition-all hover:bg-white/25' onClick={clickCloseFilterPanel}><FontAwesomeIcon icon={faXmark} /></button>
      <div className='text-xl text-white bg-header-background-color h-[50px] flex justify-center items-center'>
        Filters
      </div>
      <div className='flex gap-[10px] pt-[15px] items-center'>
        <div>
          Show only: 
        </div>
        <div className='px-[5px]'>
          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity(userControlStatus.active, filtersData[userControlStatus.active].active)} value={filtersData[userControlStatus.active].active} />
            <span>{userControlStatus.active}</span>
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity(userControlStatus.banned, filtersData[userControlStatus.banned].active)} value={filtersData[userControlStatus.banned].active} />
            <span>{userControlStatus.banned}</span>
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity(userControlStatus.expired, filtersData[userControlStatus.expired].active)} value={filtersData[userControlStatus.expired].active} />
            <span>{userControlStatus.expired}</span>
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity(userControlStatus.online, filtersData[userControlStatus.online].active)} value={filtersData[userControlStatus.online].active} />
            <span>{userControlStatus.online}</span>
          </div>

          <div className='flex items-center gap-[5px] pt-[20px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity('PlansOnly', filtersData['PlansOnly'].active)} value={filtersData['PlansOnly'].active} />
            <span>Plans only</span>
            <select className='border border-header-background-color rounded-md' value={filtersData['PlansOnly'].payload![0]['value']} onChange={changeTerm}>
              <option value={0}>term</option>
              {tariffs.sort((a:iTariffs, b:iTariffs)=>a.term-b.term).map(element=><option key={element.id} value={element.term}>{element.term}</option>)}
            </select>
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity('UsedThePromocode', filtersData['UsedThePromocode'].active)} value={filtersData['UsedThePromocode'].active} />
            <span>Used the Promocode</span>
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity('OrderDate', filtersData['OrderDate'].active)} value={filtersData['OrderDate'].active} />
            <span>Order date</span>
            <RangePicker defaultValue={[filterState.orderDateStart?dayjs(filterState.orderDateStart):null, filterState.orderDateTill?dayjs(filterState.orderDateTill):null]} className='border-header-background-color hover:border-header-background-color/75' onChange={onRangeChange} />
          </div>

          <div className='flex items-center gap-[5px]'>
            <Switch togleFunction={outsideWrapperChangeFilterActivity('Free', filtersData['Free'].active)} value={filtersData['Free'].active} />
            <span>Free</span>
          </div>

          <div className='flex justify-center items-center pt-[25px]'>
            <button className='bg-header-background-color text-white rounded-xl px-[10px] hover:bg-header-background-color/75' onClick={clickApply}>Apply</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserControlFilter
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface iModalState {
  visible: boolean
}

const initialState: iModalState = {
  visible: false
}

export const modalWindowSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>)=>{
      state.visible = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setVisible } = modalWindowSlice.actions

export const selectVisibleModalWindow=(state: RootState)=>state.modalWindow.visible

export default modalWindowSlice.reducer
import dateFormat from 'dateformat'
export const getDateInProperlyFormat=(date: Date)=>{
  return dateFormat(date, `yyyy-mm-dd'T'HH:MM`)
}

export const jsObjectToFormData =<T extends Object>(jsObject: T):FormData=>{
  const formData = new FormData()
  let key: keyof typeof jsObject
  for ( key in jsObject ) {
    formData.append(key, String(jsObject[key]).trim())
  }
  return formData
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ChangeEventHandler, FormEventHandler, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { countElementOfPageUserControl } from '../utils/constants'
import Paginator from '../components/Paginator'
import { useParams } from 'react-router-dom'
import UserControlFilter from '../components/UserControlFilter'
import axiosInstance from '../utils/generally'
import { iRowDataUserControl} from '../utils/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { selectFilter } from '../store/userControlFilterSlice'
import { AxiosResponse, isAxiosError } from 'axios'
import ModalWindow from '../components/ModalWindow'
import RowUserControlTable from '../components/RowUserControlTable'
import { getDateInProperlyFormat, jsObjectToFormData } from '../utils/common'
import OrderDetailInfo from '../components/OrderDetailInfo'
import { setAuthStatus } from '../store/authSlice'
import { selectorOrderDetails } from '../store/OrderDetailSlice'
import { Spin, notification } from 'antd'
import { selectorNotification } from '../store/notificationSlice'

interface iParapmsExecuteGetRequest{
  search?: string,
  page: number,
  active?: boolean,
  banned?: boolean,
  expired?: boolean,
  online?: boolean,
  plan?: number,
  promocode?: boolean,
  order_date_start?: string,
  order_date_end?: string,
  free?: boolean
}

const UserControl=()=>{
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData]:[iRowDataUserControl[], Function] = useState([])
  const [pages, setPages] = useState(1)
  const [endUserInput, setEndUserInput] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [visibleFilterPanel, setVisibleFilterPanel] = useState(false)

  const [api, contextHolder] = notification.useNotification()

  const {id} = useParams()
  const filtersParams = useSelector(selectFilter)
  const dispatch = useDispatch()
  const orderDetail = useSelector(selectorOrderDetails)

  const notificationData = useSelector(selectorNotification)

  const openNotification = (message: string) => {
    api.info({
      message,
      placement:'topRight',
    })
  }

  useEffect(()=>{
    if(notificationData.message&&notificationData.dateNotification){
      openNotification(notificationData.message)
    }
  // eslint-disable-next-line
  },[notificationData.dateNotification])

  const handleResultRequest=(result:AxiosResponse)=>{
    const tempTable: iRowDataUserControl[] = result.data.result
    for(let i=1; i<=tempTable.length; i++){
      tempTable[i-1].numberRow = i
      tempTable[i-1].order_date = tempTable[i-1].order_date?.split(' ')[0]
      tempTable[i-1].expire_date = tempTable[i-1].expire_date?.split(' ')[0]
      tempTable[i-1].lang = tempTable[i-1].lang?.toUpperCase()

    }
    const rowNumber = countElementOfPageUserControl - tempTable.length
    for(let i=0; i<rowNumber; i++){
      tempTable.push({sniffer:[], id: -i-1})
    }
    setData(tempTable)
    setPages(result.data.pages)
    setEndUserInput(false)
  }
  
  const executeRequest=useCallback(()=>{
    const requestParams: iParapmsExecuteGetRequest = {page: Number(id)}
    if(searchString){
      requestParams.search = searchString
    }
    if(filtersParams.Active){
      requestParams.active = true
    }
    if(filtersParams.Banned){
      requestParams.banned=true
    }
    if(filtersParams.Expired){
      requestParams.expired=true
    }
    if(filtersParams.Online){
      requestParams.online=true
    }
    if(filtersParams.plansOnly){
      requestParams.plan=Number(filtersParams.plansOnly)
    }
    if(filtersParams.usedThePromocode){
      requestParams.promocode=true
    }
    if(filtersParams.orderDateStart){
      requestParams.order_date_start = getDateInProperlyFormat(new Date(filtersParams.orderDateStart))
    }
    if(filtersParams.orderDateTill){
      requestParams.order_date_end = getDateInProperlyFormat(new Date(filtersParams.orderDateTill))
    }
    if(filtersParams.free){
      requestParams.free = true
    }

    const formDataRequest = new FormData()
    let key: keyof typeof requestParams
    for ( key in requestParams ) {
      formDataRequest.append(key, String(requestParams[key]))
    }
    axiosInstance.post('/table', jsObjectToFormData(requestParams)).then(handleResultRequest).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
        }
      }
    }).finally(()=>setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filtersParams, id, endUserInput])

  useEffect(()=>{
    setIsLoading(true)
    executeRequest()
  },[executeRequest, orderDetail])

  //---------------------------events---------------------------
  const filterOnClickHandler:MouseEventHandler=()=>{
    setVisibleFilterPanel(!visibleFilterPanel)
  }

  const submitSearchString:FormEventHandler=(event)=>{
    event.preventDefault()
    setEndUserInput(true)
    executeRequest()
  }

  const changeSearchString:ChangeEventHandler=(event)=>{
    const target = event.target as HTMLInputElement
    setSearchString(target.value)
  }

  //---------------------------render---------------------------
  return (
  <div className='flex flex-col justify-center items-center w-screen px-9'>
    {
      contextHolder
    }

    <div className='pt-[30px] flex justify-between items-center w-[100%]' >
      <button className='bg-header-background-color text-white p-[5px] rounded-xl min-w-[10rem] transition-all hover:bg-header-background-color/75' onClick={filterOnClickHandler}>Filters</button>
      <h1 className='text-header-background-color'>User control</h1>
      <form className='w-[30rem] shadow-md rounded-xl border-2 flex items-center' onSubmit={submitSearchString}>
        <label className='basis-1/12' htmlFor='searchString'><FontAwesomeIcon className='px-[5px]' icon={faMagnifyingGlass}/></label>
        <input className='basis-10/12 p-[5px]' type='text' name='searchString' id='searchString' placeholder='Search...' value={searchString} onChange={changeSearchString} />
        <button className='basis-1/12 text-white bg-header-background-color p-[5px] rounded-r-xl transition-all hover:bg-header-background-color/75'><FontAwesomeIcon icon={faArrowRight} /></button>
      </form>
    </div>

    {
        isLoading?
        <div className='w-full flex justify-center items-center pt-10'>
          <Spin size='large'/>
        </div>:
        <>
          <div className='my-[30px] shadow-2xl shadow-[#f0cae3] rounded-3xl w-[100%] table box-border overflow-hidden' >
            <div className='table-header-group'>
              <div className='table-row'>
                {['№', 'Email', 'Order', 'Order date', 'Plans (days)', 'Expires', 'Price (USD)', 'Sniffer', 'Language', 'Promocode', 'Status', ''].map(
                  (element)=><div key={element} className='border table-cell text-center text-white bg-header-background-color text-xl '>{element}</div>
                )}
              </div>
            </div>
            
              <div className='table-row-group'>
                {data.map(row=><RowUserControlTable key={row.id} rowData={row} />)} 
              </div>           
          </div>
          <Paginator baseURL={`/control`} countPages={pages} currentPage={Number(id)} />
        </>
    }
    {visibleFilterPanel&&<UserControlFilter changeVisible={setVisibleFilterPanel} />}
    <ModalWindow><OrderDetailInfo /></ModalWindow>
  </div>
  )
}

export default UserControl
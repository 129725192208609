import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userControlStatus } from '../utils/interfaces'
import { setVisible } from '../store/modalSlice'
import { selectorOrderDetails, setOrderDetail } from '../store/OrderDetailSlice'
import { Collapse, Input } from 'antd'
import axiosInstance from '../utils/generally'
import { isAxiosError } from 'axios'
import { setAuthStatus } from '../store/authSlice'
import { setNotification } from '../store/notificationSlice'

const { Panel } = Collapse

const OrderDetailInfo=()=>{
  const [loading, setLoading] = useState(false)

  const orderDetail = useSelector(selectorOrderDetails)
  const dispatch = useDispatch()

  const closeModalWindow:MouseEventHandler=()=>{
    dispatch(setVisible(false))  
  }

  const clickBanOrder:MouseEventHandler=()=>{
    setLoading(true)
    const formData = new FormData()
    formData.append('order_id', String(orderDetail.id))
    axiosInstance.post('/call_banhammer',formData).then(result=>{
      dispatch(setOrderDetail({...orderDetail, status: userControlStatus.banned}))
      setLoading(false)
    }).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
          setLoading(false)
        }
      }
    })
    dispatch(setNotification({dateNotification:new Date().toString(), message:'You request to ban has been accepted'}))
  }
  const clickBanUser:MouseEventHandler=()=>{
    setLoading(true)
    const formData = new FormData()
    formData.append('order_id', String(orderDetail.id))
    axiosInstance.post('/call_banhammer',formData).then(result=>{
      dispatch(setOrderDetail({...orderDetail, status: userControlStatus.banned}))
      setLoading(false)
    }).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
          setLoading(false)
        }
      }
    })
    dispatch(setNotification({dateNotification:new Date().toString(), message:'You request to ban has been accepted'}))
  }

  return(
  <div className='w-full relative'>
    <h1 className='text-2xl text-center bg-header-background-color text-white py-[5px]'>Order details</h1>
    {orderDetail.status===userControlStatus.active&&<span className='absolute top-2 left-3 font-semibold text-blue-400'>{orderDetail.status}</span>}
    {orderDetail.status===userControlStatus.banned&&<span className='absolute top-2 left-3 font-semibold text-red-400'>{orderDetail.status}</span>}
    {orderDetail.status===userControlStatus.expired&&<span className='absolute top-2 left-3 font-semibold text-yellow-400'>{orderDetail.status}</span>}
    {orderDetail.status===userControlStatus.online&&<span className='absolute top-2 left-3 font-semibold text-green-400'>{orderDetail.status}</span>}
    <button className='absolute rounded-md top-2 right-3 text-sm text-white border-2 border-red px-[5px] transition-all hover:bg-white/25' onClick={closeModalWindow}><FontAwesomeIcon icon={faXmark} /></button>
    <form className='flex flex-col gap-[5px] p-[20px]'>
      <fieldset className='border flex flex-col gap-[10px] p-[5px]'>
      <legend>Order info</legend>
        <Input addonBefore='Order' defaultValue={orderDetail.cert_name} readOnly />
        <div className='flex gap-[5px]'>
          <Input addonBefore='Order-date' defaultValue={orderDetail.order_date} readOnly />
          <Input addonBefore='Order-expire' defaultValue={orderDetail.expire_date} readOnly />
        </div>
        <div className='flex gap-[5px]'>
          <Input addonBefore='Plan' defaultValue={orderDetail.plan} readOnly />
          <Input addonBefore='Price' defaultValue={orderDetail.price||'free'} readOnly />
        </div>
        <Input addonBefore='Promocode' defaultValue={orderDetail.promocode||''} readOnly />
      </fieldset>

      <fieldset className='border flex flex-col gap-[10px] p-[5px]'>
      <legend>Additional info</legend>
        <Input addonBefore='Email' defaultValue={orderDetail.email} readOnly />
        <Input addonBefore='Language' defaultValue={orderDetail.lang} readOnly />
        {
        orderDetail.sniffer.length>0&&
        <Collapse>
        <Panel header='ip-address list' key={'1'} className='p-0'>
          <Collapse>
            {orderDetail.sniffer.map((element,index)=>
              <Panel header={`${element.ip}(${element.date})`} key={index}>
              <div className='flex gap-[5px]'>
              <div>{element.country}-({element.city})</div>
              {element.country&&<img className='object-contain' alt='country' src={`https://flagcdn.com/20x15/${element.country.toLowerCase()}.png`}/>}
              </div>
              <div>{element.org}</div>
              <div className='text-blue-600'>{element.ip}</div>
            </Panel>
              )}
          </Collapse>
        </Panel>
      </Collapse>
      }
      </fieldset>
    </form>
    <div className='flex justify-around items-stretch w-[100%]'>
      <button disabled={orderDetail.status===userControlStatus.banned || loading} 
        onClick={clickBanUser} 
        className={`flex-auto text-white px-[5px] py-[15px] min-w-[10rem] transition-all ${orderDetail.status===userControlStatus.banned||loading?'bg-gray-400':'bg-red-500 hover:bg-red-700'}`}
      >
        Ban user
      </button>
      <button 
        disabled={orderDetail.status===userControlStatus.banned || loading} 
        onClick={clickBanOrder} 
        className={`flex-auto text-white px-[5px] py-[15px] min-w-[10rem] transition-all ${orderDetail.status===userControlStatus.banned||loading?'bg-gray-400':'bg-red-500 hover:bg-red-700'}`}
      >
        Ban order
      </button>
    </div>
  </div>
  ) 
}

export default OrderDetailInfo
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface iInitialState{
  dateNotification: string,
  message: string
}

const initialState: iInitialState = {
  dateNotification: '',
  message: ''
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<iInitialState>)=>{
      state.dateNotification = action.payload.dateNotification
      state.message = action.payload.message
    }
  },
})

// Action creators are generated for each case reducer function
export const { setNotification } = notificationSlice.actions

export const selectorNotification=(state: RootState)=>state.notification

export default notificationSlice.reducer
import { useState, useEffect } from 'react'
import { BACKEND_URL } from '../utils/constants'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setAuthStatus } from '../store/authSlice'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../localStoragesItemsName'

interface iData{
  username: string,
  password: string
}

const Login=()=>{
  const [data, setData] = useState({} as iData)
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useDispatch()

  useEffect(()=>{
    const refresh = localStorage.getItem(REFRESH_TOKEN)
    if(refresh){
      axios.post(`${BACKEND_URL}/login/refresh/`, {refresh}).then(responseQuery=>{
        localStorage.setItem(ACCESS_TOKEN, responseQuery.data.access)
        dispatch(setAuthStatus(true))
      })
    }
  } ,[dispatch])

  const changeInputs:React.ChangeEventHandler=(event)=>{
    const target = event.target as HTMLInputElement

    setData({...data, [target.name]: target.value})
  }

  const submitEvent:React.FormEventHandler=(event)=>{
    event.preventDefault()
    axios.post(`${BACKEND_URL}/`, data).then(responseQuery=>{
      localStorage.setItem(ACCESS_TOKEN, responseQuery.data.access)
      localStorage.setItem(REFRESH_TOKEN, responseQuery.data.refresh)
      dispatch(setAuthStatus(true))
    }).catch(error=>{
      if(axios.isAxiosError(error)){
        if(error.response?.status && error.response.status >= 400 && error.response?.data.detail){
          setErrorMessage(error.response?.data.detail)
        }else{
          setErrorMessage(error.message)
        }
       
      }
    })
  }

  return (
    <div className='h-screen w-screen flex flex-col justify-center items-center'>
      <div className='flex flex-col justify-center items-center w-[70%] px-[20px] py-[20px] shadow-2xl shadow-[#f0cae3]'>
        <h1 className='text-header-background-color text-2xl'>Sign in</h1>
        <form 
          className='flex flex-col justify-center items-center gap-y-[15px] py-[25px] w-[80%]' 
          onSubmit={submitEvent}
        >
          <input 
            className='shadow-md rounded-md border-2 w-[80%]' 
            type='text' 
            name='username' 
            id='login' 
            placeholder='Login' 
            value={data.username} 
            onChange={changeInputs} 
          />

          <input 
            className='shadow-md rounded-md border-2 w-[80%]' 
            type='password' 
            name='password' 
            id='password' 
            placeholder='Password' 
            value={data.password} 
            onChange={changeInputs} 
          />

          {errorMessage&&<div className='text-rose-600'>{errorMessage}</div>}
          <input 
            className='hover:cursor-pointer rounded-[15px] px-[15px] py-2 mt-[30px] bg-btn-secondary-color border-2' 
            type='submit' 
            value='Sign in' 
          />
        </form>
      </div>
    </div>)
 
}

export default Login
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface iPropsPaginator{
  countPages: number,
  currentPage: number,
  baseURL: string
}

interface iPagesItem{
  id:number,
  title: string,
  path: number
}

const Paginator=({countPages, currentPage, baseURL }:iPropsPaginator)=>{
  const [listPages, setListPages]:[iPagesItem[], Function] = useState([])

  useEffect(()=>{
    const tempPagesList: iPagesItem[]= []
    if(countPages<=9){
      for(let i=0; i<countPages; i++){
        tempPagesList.push({id: i, title: String(i+1), path: i+1})
      }
    }else{
      if(currentPage<=5){
        for(let i=0; i<7; i++){
          tempPagesList.push({id: i, title: String(i+1), path: i+1})
        }
        tempPagesList.push({id: 7, title: '...', path: 7})
        tempPagesList.push({id: 8, title: String(countPages), path: countPages})
      }

      if(currentPage>=countPages-5){
        tempPagesList.push({id: 0, title: '1', path: 1})
        tempPagesList.push({id: 1, title: '...', path: countPages-7})
        for(let i=0; i<7; i++){
          tempPagesList.push({id: i+2, title: String(countPages-6+i), path: countPages-6+i})
        }
      }

      if(currentPage>5 && currentPage<countPages-5){
        tempPagesList.push({id: 0, title: '1', path: 1})
        tempPagesList.push({id: 1, title: '...', path: currentPage-3})

        for(let i=0; i<5; i++){
          tempPagesList.push({id: i+2, title: String(currentPage-2+i), path: currentPage-2+i})
        }

        tempPagesList.push({id: 7, title: '...', path: currentPage+3})
        tempPagesList.push({id: 8, title: String(countPages), path: countPages})
      }
    }
    setListPages(tempPagesList)
  },[currentPage, countPages])

  return (
  <>
  {countPages>1?
  <div className='py-9 flex flex-row justify-between items-center w-[100%] text-white'>
    {currentPage <= 1?
      <div  className='border p-1.5 rounded-md bg-slate-400'><FontAwesomeIcon icon={faChevronLeft}/></div>:
      <Link className='border p-1.5 rounded-md bg-header-background-color' to={`${baseURL}/${currentPage-1}` }><FontAwesomeIcon icon={faChevronLeft}/></Link>
    }
    <div className='flex flex-row gap-[15px]'>
      {listPages.map(element=><Link className={`border p-1.5 rounded-md ${currentPage===element.path?'bg-btn-primary-color':'bg-header-background-color'}`} key={element.id} to={`${baseURL}/${element.path}`}>{element.title}</Link>)}
    </div>
    {currentPage>=countPages?
      <div  className='border p-1.5 rounded-md bg-slate-400'><FontAwesomeIcon icon={faChevronRight}/></div>:
      <Link className='border p-1.5 rounded-md bg-header-background-color' to={`${baseURL}/${currentPage+1}` }><FontAwesomeIcon icon={faChevronRight}/></Link>
    }
  </div>:
  <></>
  } 
  </>
  
  )
}

export default Paginator
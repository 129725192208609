import { MouseEventHandler } from 'react'

interface iPropsSwitch{
  value: boolean,
  togleFunction: MouseEventHandler
}

const Switch=({value, togleFunction}:iPropsSwitch)=>{

  return( 
  <div className={`${value?'border-green-400':' border-header-background-color'} flex items-center border-2 rounded-full w-[35px] h-[20px] hover:cursor-pointer`} onClick={togleFunction} >
    <div className={`${value?'bg-green-400 translate-x-full':'bg-header-background-color'} transition-all rounded-full w-[15px] h-[15px]`}></div>
  </div>
  )
}

export default Switch
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { iRowDataUserControl } from '../utils/interfaces'

const initialState: iRowDataUserControl = {
  id: 0,
  promocode: undefined,
  plan: undefined,
  status: undefined,
  cert_name: undefined,
  email: undefined,
  expire_date: undefined,
  is_promocode: undefined,
  lang: undefined,
  numberRow: undefined,
  online: undefined,
  order_date: undefined,
  price: undefined,
  sniffer:[]
}

export const orderDetailSlice = createSlice({
  name: 'orderDetail',
  initialState,
  reducers: {
    setOrderDetail: (state, action: PayloadAction<iRowDataUserControl>)=>{
      Object.assign(state, action.payload)
    }
  },
})

// Action creators are generated for each case reducer function
export const { setOrderDetail } = orderDetailSlice.actions

export const selectorOrderDetails=(state: RootState)=>state.orderDetail

export default orderDetailSlice.reducer
import { environmentTypes, userControlStatus } from './interfaces'
import dataLocation from '../location.json'

export const BACKEND_URL = 'https://admin.4eburashe4ka.net-ray.com'
// export const BACKEND_URL = 'http://localhost'
export const environment = environmentTypes.develop

export const countElementOfPageUserControl = 40

const languageBrowser = navigator.language.slice(0,2)
type Activity = typeof dataLocation.locations[0]
export const languageName = (dataLocation.enableLanguages.find(el=>el===languageBrowser)?.length)? languageBrowser as keyof Activity: 'ua' as keyof Activity

export const colorOfUserControlStatus = {
    [userControlStatus.active]: 'blue-600',
    [userControlStatus.banned]: 'red-600',
    [userControlStatus.expired]: 'yellow-400',
    [userControlStatus.online]: 'green-600'
}
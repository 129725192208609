import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthStatus } from '../store/authSlice'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../localStoragesItemsName'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import logo from '../img/justLogo.png'

interface iMenuList{
  id: number,
  path: string,
  title: string
}

interface iPropsHeader{
  children: React.ReactNode
}

const Header=({children}:iPropsHeader)=>{
  let location = useLocation()

  const dispatch = useDispatch()
  const onClickLogOut:React.MouseEventHandler=(event)=>{
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(ACCESS_TOKEN)
    dispatch(setAuthStatus(false))
  }
  const navMenu:iMenuList[]=[
    {id: 0, path: '/new/location', title:'New location'},
    {id: 1, path: '/control/1', title:'User control'},
    {id: 2, path: '/logs', title:'Log maneger'},
    {id: 3, path: '/dashbords', title:'Dashbords'},
  ]

  const stylesAvtiveBtn = 'rounded-[15px] px-[15px] py-2 bg-btn-primary-color'
  return(
  <>
    <div className='w-[100%] bg-header-background-color flex flex-row justify-between items-center text-white'>
      <div className='px-5 py-5 ml-[10px]'>
        <Link to='/new/location'>
          <img className='w-10 h-10' src={logo} alt='logo' />
        </Link>
      </div>

      <nav>
        <ul className='flex flex-row gap-x-6 items-center'>
          {navMenu.map((element)=><li key={element.id}><Link className={location.pathname===element.path?stylesAvtiveBtn:''} to={element.path}>{element.title}</Link></li>)}
        </ul>
      </nav>

      <div>
        <div className='hover:cursor-pointer hover:bg-gray-200 rounded-[15px] px-[15px] py-2 mr-[10px] bg-white text-header-background-color' onClick={onClickLogOut}>Log out<FontAwesomeIcon className='px-[5px]' icon={faRightFromBracket} /></div>
      </div>
    </div>
    <div className='flex justify-center'>
      {children}
    </div>
  </>
  )
}

export default Header
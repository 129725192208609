import axios , {AxiosRequestConfig} from 'axios'
import { BACKEND_URL } from './constants'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../localStoragesItemsName';
import { store } from '../store/store';
import { setAuthStatus } from '../store/authSlice';

const axiosInstance = axios.create({
  baseURL: BACKEND_URL
})

axiosInstance.interceptors.request.use(
  (config:AxiosRequestConfig) => {
    const token = String(localStorage.getItem(ACCESS_TOKEN))
    if (token) {
      config.headers!["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers!["x-access-token"] = token // for Node.js Express back-end
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },

  async (err) => {
    const originalConfig: AxiosRequestConfig = err.config
    if (originalConfig.url !== "/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        
        try {
          const rs = await axios.post(`${BACKEND_URL}/login/refresh/`, {
            refresh: localStorage.getItem(REFRESH_TOKEN),
          })
          const { access, refresh } = rs.data
          localStorage.setItem(ACCESS_TOKEN, access)
          localStorage.setItem(REFRESH_TOKEN, refresh)

          return axiosInstance(originalConfig)
        } catch (_error) {
          localStorage.removeItem(ACCESS_TOKEN)
          localStorage.removeItem(REFRESH_TOKEN)
          store.dispatch(setAuthStatus(false))
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
);

export default axiosInstance
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from '../pages/Login'
import { pagesList } from '../utils/pagesList'
import { useSelector } from 'react-redux'
import { selectorAuthState } from '../store/authSlice'


const Routing=()=>{

  const authState = useSelector(selectorAuthState)

  const pathBeforeLogin = (<>
  <Route path='/login' element={<Login />}/>
  <Route
      path='*'
      element={<Navigate to='/login' replace />}
  />
  </>)

  const pathAfterLogin = (
    <>
      {pagesList.map(element=><Route key={element.id} path={element.path} element={element.component} />)}

      <Route
          path='*'
          element={<Navigate to='/new/location' replace />} />
    </>)
  return(
      <BrowserRouter>
      <Routes>
          {authState?pathAfterLogin:pathBeforeLogin}
      </Routes>
      </BrowserRouter>
  );
}

export default Routing
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface iAuthStateState {
  login: string,
  authState: boolean | undefined
}

const initialState: iAuthStateState = {
  login: '',
  authState: undefined
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<boolean>)=>{
      state.authState = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuthStatus } = authSlice.actions

export const selectorAuthState=(state: RootState)=>state.auth.authState

export default authSlice.reducer
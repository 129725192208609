import { MouseEventHandler, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { iRowDataUserControl, userControlStatus } from "../utils/interfaces"
import { setVisible } from "../store/modalSlice"
import { setOrderDetail } from "../store/OrderDetailSlice"
import DropdownControlButton from "./DropdownControlButton"

interface iPropsRowUserControlTable{
  rowData: iRowDataUserControl
}

const RowUserControlTable=({rowData}:iPropsRowUserControlTable)=>{
  const [orderPrompt, setOrderPrompt] = useState(false)
  const [ipAddressPrompt, setIpAddressPrompt] = useState(false)
  const [visibleContextMenuButtonControl, setVisibleContextMenuButtonControl] = useState(false)

  const dispatch = useDispatch()
  const refContolColomn = useRef<HTMLDivElement>(null)

  const clickOnTableRow:MouseEventHandler=(event)=>{
    const target = event.target as HTMLElement
    if(refContolColomn.current && !refContolColomn.current.contains(target)&&rowData.cert_name){
      dispatch(setOrderDetail(rowData))
      dispatch(setVisible(true))
    }
  }

  return(
    <div onClick={clickOnTableRow} className='table-row leading-10 odd:bg-stone-200 text-header-background-color min-h-[2.5rem] h-[2.5rem] hover:cursor-pointer'>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.numberRow}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.email}
    </div>
    <div className='px-[3px] border table-cell text-center'>
    {rowData.cert_name&&<>
      <div onMouseOver={()=>setOrderPrompt(true)} onMouseOut={()=>setOrderPrompt(false)}>{rowData.cert_name?.slice(0, 8)+'...'}</div>
      {orderPrompt&&<div className='absolute bg-white border-2 shadow-md transition-all'>{rowData.cert_name}</div>}
      </>
    }
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.order_date}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.plan}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.expire_date}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.price}
    </div>
    <div className='px-[3px] border table-cell text-center'>
    <div className='flex justify-between items-center'><div className='flex-auto text-blue-600'>{rowData.sniffer.at(-1)?.ip}</div>{rowData.sniffer.at(-1)?.ip&&<span onMouseOver={()=>setIpAddressPrompt(true)} onMouseOut={()=>setIpAddressPrompt(false)} className='inline-block cursor-pointer bg-header-background-color hover:bg-header-background-color/75 text-sm text-center text-white rounded-full w-4 h-4 mx-[3px] flex items-center justify-center'>!</span>}</div>
      <div>{ipAddressPrompt&&
        <div className='absolute bg-white border-2 shadow-md transition-all flex flex-col leading-6 p-[10px]'>
          <div className='flex gap-[5px] justify-center'>
            <div>{rowData.sniffer.at(-1)?.country}-({rowData.sniffer.at(-1)?.city})</div>
            {rowData.sniffer.at(-1)?.country&&<img className='object-contain' alt='country' src={`https://flagcdn.com/20x15/${rowData.sniffer.at(-1)?.country.toLowerCase()}.png`}/>}
          </div>
          <div>{rowData.sniffer.at(-1)?.org}</div>
          <div className='text-blue-600'>{rowData.sniffer.at(-1)?.ip}</div>
        </div>
        }</div>
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.lang}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {rowData.cert_name&&rowData.is_promocode&&<span>&#x2713;</span>}
    </div>
    <div className='px-[3px] border table-cell text-center'>
      {
        // this is peculiarity of tailwind
      }
      {rowData.status===userControlStatus.active&&<span className='font-semibold text-blue-600'>{rowData.status}</span>}
      {rowData.status===userControlStatus.banned&&<span className='font-semibold text-red-600'>{rowData.status}</span>}
      {rowData.status===userControlStatus.expired&&<span className='font-semibold text-yellow-400'>{rowData.status}</span>}
      {rowData.status===userControlStatus.online&&<span className='font-semibold text-green-600'>{rowData.status}</span>}
    </div>

    <div ref={refContolColomn} className='px-[3px] border table-cell text-center'>
      {
        rowData.cert_name&&<DropdownControlButton rowData={rowData} visible={visibleContextMenuButtonControl} setVisible={setVisibleContextMenuButtonControl} />
      }
    </div>
  </div> 
  )
}

export default RowUserControlTable
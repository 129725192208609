import { isAxiosError } from 'axios'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import axiosInstance from '../utils/generally'
import { iRowDataUserControl, userControlStatus } from '../utils/interfaces'
import { setAuthStatus } from '../store/authSlice'
import { setNotification } from '../store/notificationSlice'


interface iPropsDropdownControlButton{
  visible: boolean,
  setVisible: Function,
  rowData: iRowDataUserControl,
}

const DropdownControlButton=({visible, setVisible, rowData}:iPropsDropdownControlButton)=>{
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const handleClickOutside=(event: MouseEvent)=>{
      const target = event.target as HTMLElement
      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        setVisible(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef, setVisible])

  const clickBanOrder:MouseEventHandler=()=>{
    setLoading(true)
    const formData = new FormData()
    formData.append('order_id', String(rowData.id))
    axiosInstance.post('/call_banhammer',formData).then(result=>{
      setLoading(false)
    }).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
        }
      }
    })
    dispatch(setNotification({dateNotification:new Date().toString(), message:'You request to ban has been accepted'}))
  }

  const clickBanUser:MouseEventHandler=()=>{
    setLoading(true)
    const formData = new FormData()
    formData.append('email', String(rowData.email))
    axiosInstance.post('/ban_user',formData).then(result=>{
      setLoading(false)
    }).catch(error=>{
      if(isAxiosError(error)){
        if(error.response!.status>=400&&error.response!.status<=500){
          dispatch(setAuthStatus(false))
        }
      }
    })
    dispatch(setNotification({dateNotification:new Date().toString(), message:'You request to ban has been accepted'}))
  }

  return (
    <div>
      <button onClick={()=>{setVisible(true)}} className='bg-header-background-color rounded-md px-[4px] text-xs text-white py-[1px] leading-4 hover:bg-header-background-color/75'>control</button>
      {visible&&
      <div  ref={wrapperRef} className='absolute bg-white border-2 shadow-md transition-all flex flex-col gap-[10px] leading-6 p-[10px] translate-x-[-30%]'>
        <button 
          onClick={clickBanUser} 
          disabled={rowData.status===userControlStatus.banned || loading} 
          className={`rounded-md px-[4px] text-xs text-white py-[1px] leading-4 ${rowData.status===userControlStatus.banned || loading?'bg-gray-400':'bg-red-500 hover:bg-red-700'}`}
        >
          Ban user
        </button>
        <button 
          onClick={clickBanOrder} 
          disabled={rowData.status===userControlStatus.banned || loading} 
          className={`rounded-md px-[4px] text-xs text-white py-[1px] leading-4 ${rowData.status===userControlStatus.banned || loading?'bg-gray-400':'bg-red-500 hover:bg-red-700'}`}
        >
          Ban order
        </button>
      </div>}
    </div>
  )
}


export default DropdownControlButton
import { configureStore } from '@reduxjs/toolkit'
import { environment } from '../utils/constants'
import { environmentTypes } from '../utils/interfaces'
import authSlice from './authSlice'
import modalSlice from './modalSlice'
import OrderDetailSlice from './OrderDetailSlice'
import userControlFilterSlice from './userControlFilterSlice'
import notificationSlice from './notificationSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    modalWindow: modalSlice,
    controlUserFilter: userControlFilterSlice,
    orderDetail: OrderDetailSlice,
    notification: notificationSlice,
  },
  devTools: environment===environmentTypes.develop //maybe need create variable const environment: string = 'production'  or 'dev'
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
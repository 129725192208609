import Header from '../layouts/Header'
import Location from '../pages/Location'
import PageWillBeDevelop from '../pages/PageWillBeDevelop'
import UserControl from '../pages/UserControl'

interface iPagesList{
  id: number,
  path: string,
  title: string, 
  component: React.ReactNode
}
export const pagesList:iPagesList[]=[
  {id: 0, path: '/new/location', title:'New location', component:<Header><Location /></Header>},
  {id: 1, path: '/control/:id', title:'User control', component:<Header><UserControl /></Header>},
  {id: 2, path: '/logs', title:'Log maneger', component:<Header><PageWillBeDevelop /></Header>},
  {id: 3, path: '/dashbords', title:'Dashbords', component:<Header><PageWillBeDevelop /></Header>},
]